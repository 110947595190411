/**
 * Renders a Heading with a Description and a Button
 * optional props are colors for heading and description
 */

import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import { Heading2 } from '../../atoms/Headings/Headings';
import {
  breakpoints,
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  menuColors,
  unitConverter as uc,
} from '../../../styles/base';
import sanityImage from '../../../utils/sanityImage';
import Image from '../../atoms/Image/Image';
import Section from '../../molecules/Section/Section';
import Text from '../../atoms/Text/Text';

const IconHeadingSection = ({
  align,
  className,
  description,
  headingText,
  headingTextColor,
  icon,
  sectionStyles,
}) => {
  const iconHeadingSectionCss = css`
    display: block;
    max-width: ${uc('900px')};
    margin: 0 auto ${uc('20px')};
    text-align: ${align};

    @media (${breakpoints.tablet}) {
      margin: ${uc('0 20px 20px')};
    }
  `;

  const imageCss = css`
    margin-bottom: ${uc('30px')};
  `;

  const headingTextCss = css`
    color: ${menuColors[headingTextColor]};
    font-weight: ${fontWeights.bold};
    font-size: ${fontSizes.thirty};
    line-height: ${lineHeights.title};

    @media (${breakpoints.tablet}) {
      font-size: ${fontSizes.twentyEight};
    }

    @media (${breakpoints.mobile}) {
      font-size: ${fontSizes.twentyTwo};
    }
  `;

  const descriptionCss = css`
    max-width: ${uc('650px')};
    margin: 0 auto;
  `;

  return (
    <Section sectionStyles={sectionStyles}>
      <div css={iconHeadingSectionCss} className={className}>
        {sanityImage(icon).url() && (
          <Image
            css={imageCss}
            src={sanityImage(icon)
              .auto('format')
              .height(100)
              .url()}
            alt={icon.alt}
          />
        )}
        <Heading2 css={headingTextCss}>{headingText}</Heading2>
        <Text fontSize={fontSizes.sixteen} css={descriptionCss}>
          {description}
        </Text>
      </div>
    </Section>
  );
};

/**
 * Prop types
 */
IconHeadingSection.propTypes = {
  align: PropTypes.string,
  className: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  description: PropTypes.string,
  headingText: PropTypes.string,
  headingTextColor: PropTypes.string,
  icon: PropTypes.shape({ alt: PropTypes.string }),
  sectionStyles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
};

/**
 * Default props
 */
IconHeadingSection.defaultProps = {
  align: 'inherit',
  className: '',
  description: '',
  headingText: '',
  headingTextColor: colors.darkGray.three,
  icon: {},
  sectionStyles: {},
};

export default IconHeadingSection;
